/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/*jshint -W061 */

(function($) {

  jQuery.extend({
    isValidSelector: function(selector) {
      if (typeof(selector) !== 'string') {
        return false;
      }
      try {
        var $element = $(selector);
      } catch(error) {
        return false;
      }
      return true;
    }
  });

  function watchNavigationScroll() {
    if ($(window).scrollTop() > 50) {
      $('body').addClass('scrolled');
    }
    else {
      $('body').removeClass('scrolled');
    }
  }

  function add_marker( $marker, map ) {
    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

    var map_icon = null;

    var idx = $marker.attr('data-idx');

    if( $marker.attr('data-icon') ) {
      map_icon = new google.maps.MarkerImage($marker.attr('data-icon'), null, null, null, new google.maps.Size($marker.attr('data-icon-width'),$marker.attr('data-icon-height')));
    }
    else {
      map_icon = new google.maps.MarkerImage(js_vars.theme_url + '/dist/images/map-marker.png', null, null, null, new google.maps.Size(64,64));
    }

    var args = {
      position: latlng,
      map: map,
      icon: map_icon,
      animation: google.maps.Animation.DROP
    };

    if($marker.attr('data-title')) {
      args.title = $marker.attr('data-title');
    }

    var marker = new google.maps.Marker( args );

    map.markers.push(marker);

    if ($marker.find('.marker-info').length) {
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html()
      });

      google.maps.event.addListener(infowindow, 'domready', function(a, b) {
        if( $marker.find('.custom-marker-image').length ) {
          var __marker = $('.marker-info[data-idx="'+idx+'"]');

          __marker.closest('.gm-style-iw-c').addClass('with-marker-image');

          infowindow.setOptions({
            pixelOffset: new google.maps.Size(100, 20),
            maxWidth: __marker.find('.custom-marker-image .img-fluid').attr('width')
          });

          if($marker.attr('data-cover-markers')) {
            infowindow.setZIndex(999);
          }
        }
      });

      infowindow.open(map, marker);

      // google.maps.event.addListener(marker, 'click', function () {
      //   infowindow.open(map, marker);
      // });
    }
    else if($marker.attr('data-marker-click')) {
      google.maps.event.addListener(marker, 'click', function () {
        window.open( "https://maps.google.com?q=" + $marker.attr('data-marker-click'), '_blank' );
      });
    }
    else if($marker.attr('data-link')) {
      google.maps.event.addListener(marker, 'click', function () {
        window.location.href = $marker.attr('data-link');
      });
    }
  }

  function center_map( map ) {
    var bounds = new google.maps.LatLngBounds();

    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

      bounds.extend(latlng);
    });

    if (map.markers.length === 1) {
      map.setCenter(bounds.getCenter());
      map.setZoom(16);
    }
    else {
      map.fitBounds(bounds, $(window).width() < 500 ? 30 : 50);
    }
  }

  function new_map( $el ) {
    var $markers = $el.find('.map-marker');

    var args = {
      zoom: 16,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: 'satellite',
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP,
      }
    };

    if( $el.attr('data-disable-default-ui') ) {
      args.disableDefaultUI = true;
    }

    var styles = [
      {
        featureType: "poi",
        // elementType: "business",
        stylers: [
          { visibility: "off" }
        ]
      }
    ];

    if( $el.find('.custom-styles').length ) {
      styles = eval( $el.find('.custom-styles').text() );
    }

    var map = new google.maps.Map($el[0], args);

    // map.setOptions({styles: styles});

    map.markers = [];

    $markers.each(function () {
      add_marker($(this), map);
    });

    center_map(map);

    $(window).on('resize', function() {
      google.maps.event.trigger(map, 'resize');
    });

    return map;
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        watchNavigationScroll();

        $(window).on('load resize scroll orientationchange', function () {
          watchNavigationScroll();
        });

        $('section.section-poi .poi-carousel').each(function() {
          var t = $(this), section = t.closest('section');

          if( t.find('.item-slide').length > 1 ) {
            t.slick({
              fade: true,
              autoplay: false,
              dots: false,
              arrows: true,
              appendArrows: section.find('.carousel-controls .container')
            });
          }
        });

        $('section.section-images-carousel .images-carousel > div').each(function() {
          var t = $(this), section = t.closest('section');

          if( t.find('.item-slide').length > 1 ) {
            t.slick({
              autoplay: false,
              dots: false,
              arrows: true,
              centerMode: true,
              centerPadding: 0,
              variableWidth: true,
              appendArrows: section.find('.carousel-controls .container'),
              responsive: [
                {
                  breakpoint: 1420,
                  settings: {
                    variableWidth: false,
                    centerPadding: '100px'
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    variableWidth: false,
                    centerPadding: '50px'
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    variableWidth: false,
                    centerPadding: '30px'
                  }
                }
              ]
            });
          }
        });

        $('.open-brochure-button, .request-callback-button, a[href="#get-offer"]').magnificPopup({
          type: 'inline',
          preloader: false,
          removalDelay: 150,
		      mainClass: 'mfp-fade'
        });

        $(document).on('click', '.scroll-to-top', function(e) {
          e.preventDefault();

          $('html,body').stop().scrollTop(0);
        });

        $(document).on('click', '.apartments-list article [data-toggle="flip"]', function(e) {
          e.preventDefault();

          $(this).closest('article').toggleClass('flipped');
        });

        $(document).on('click', '.material-text-toggle', function(e) {
          e.preventDefault();

          var item = $(this).closest('.material-item');

          if( item.hasClass('expanded') ) {
            item.removeClass('expanded');
            item.find('.material-text').slideUp(300);
          }
          else {
            item.addClass('expanded');
            item.find('.material-text').slideDown(300);
          }
        });

        $('.acf-map').each(function(){
          map = new_map( $(this) );
        });

        $(document).on('click', 'section.section-apartments-list .bottom-information-text .close-info', function(e) {
          e.preventDefault();

          $(this).closest('.bottom-information-text').fadeOut('fast');
        });

        $(document).on('click', 'section.section-popup-notice .close-info', function(e) {
          e.preventDefault();

          $(this).closest('.section-popup-notice').fadeOut('fast');
        });

        $(document).on('click', 'section.section-hotpoints-image .mobile-bottom-icons .icon-item-wrapper', function(e) {
          e.preventDefault();

          $(this).toggleClass('on').siblings().removeClass('on');
        });

        $(document).on('click', 'a[href^="#"]', function(e) {
          var href = $(this).attr('href');
          var wait = 0;

          if( $.isValidSelector( href ) ) {
            var target = $( href );
            
            if( target.length && target.hasClass('collapse') ) {
              e.preventDefault();

              if( target.is(':hidden') ) {
                target.slideDown(400);
                target.find('.slick-slider').slick('setPosition');
                wait = 100;
              }

              setTimeout(function() {
                $(window).scrollTop( target.offset().top - $('#header').outerHeight() );
              }, wait);
            }
          }

          $(this).toggleClass('on').siblings().removeClass('on');
        });

        var wow = new WOW(
          {
            boxClass: 'wow',      // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            offset: 70,          // distance to the element when triggering the animation (default is 0)
            mobile: true,       // trigger animations on mobile devices (default is true)
            live: true,       // act on asynchronously loaded content (default is true)
            callback: function (box) {
              $(box).addClass('appeared');
            },
            scrollContainer: null // optional scroll container selector, otherwise use window
          }
        );
        wow.init();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
